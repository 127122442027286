import React from 'react';
import styled from 'styled-components';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { IIconProps } from '../../Icons/IIconProps.type.ts';
import { Icon } from '../../Icons/types.ts';
import { StyledQuinaryButton } from './components/StyledQuinaryButton.tsx';
import {
  quinaryButtonIconColorDisabledVarName,
  quinaryButtonIconColorHoverVarName,
  quinaryButtonIconColorVarName,
} from './constants.ts';

interface IQuinaryButtonIconProps
  extends Pick<IIconProps, 'children' | 'color' | 'screenReaderText'> {
  readonly icon: Icon;
}

export const StyledQuinaryButtonIconWrapper = styled.span`
  color: var(${quinaryButtonIconColorVarName});

  ${StyledQuinaryButton}:enabled:hover &&,
  ${StyledQuinaryButton}:enabled:active && {
    color: var(${quinaryButtonIconColorHoverVarName});
  }

  ${StyledQuinaryButton}:disabled && {
    color: var(${quinaryButtonIconColorDisabledVarName});
  }
`;

export const QuinaryButtonIcon: React.FC<IQuinaryButtonIconProps> = ({ icon, ...otherProps }) => {
  const IconComponent = icon;

  return (
    <StyledQuinaryButtonIconWrapper {...getDataUiComponentAttribute(QuinaryButtonIcon)}>
      <IconComponent size={IconSize.S} {...otherProps} />
    </StyledQuinaryButtonIconWrapper>
  );
};

QuinaryButtonIcon.displayName = 'QuinaryButtonIcon';
