import React, { TransitionEventHandler, useEffect, useRef } from 'react';
import { ComponentLibraryGlobals } from '../../globals/componentLibraryGlobals.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IIconProps } from '../Icons/IIconProps.type.ts';
import { Icons } from '../Icons/components/icons.ts';
import { ChevronDirection, ChevronDirectionToAngleMap } from './chevronDirection.ts';
import { StyledChevron } from './components/StyledChevron.tsx';

export interface IChevronProps
  extends Pick<IIconProps, 'children' | 'color' | 'screenReaderText' | 'size'> {
  readonly direction: ChevronDirection;
  readonly onTransitionEnd?: TransitionEventHandler<HTMLSpanElement>;
}

const getClosestAngle = (prevAngle: number, angle: number): number => {
  const n = Math.floor(prevAngle / 360);
  const candidate1 = n * 360 + angle;
  const candidate2 = (n - 1) * 360 + angle;
  const candidate3 = (n + 1) * 360 + angle;

  const deltaToCandidate1 = Math.abs(prevAngle - candidate1);
  const deltaToCandidate2 = Math.abs(prevAngle - candidate2);
  const deltaToCandidate3 = Math.abs(prevAngle - candidate3);

  const min = Math.min(deltaToCandidate1, deltaToCandidate2, deltaToCandidate3);

  if (min === deltaToCandidate1) {
    return candidate1;
  }
  if (min === deltaToCandidate2) {
    return candidate2;
  }

  return candidate3;
};

export const Chevron = React.forwardRef<HTMLSpanElement, IChevronProps>(
  ({ direction, onTransitionEnd, size = IconSize.S, ...iconProps }, forwardedRef) => {
    const directionAngle = ChevronDirectionToAngleMap[direction];
    const prevAngleRef = useRef<number>(directionAngle);
    const prevAngle = prevAngleRef.current;
    const angle = getClosestAngle(prevAngle, directionAngle);

    useEffect(() => {
      prevAngleRef.current = angle;
    }, [angle]);

    return (
      <StyledChevron
        ref={forwardedRef}
        angle={angle}
        skipAnimation={ComponentLibraryGlobals.skipAnimation}
        onTransitionEnd={onTransitionEnd}
        $size={size}
        {...getDataUiComponentAttribute(Chevron)}
      >
        <Icons.ChevronDown {...iconProps} />
      </StyledChevron>
    );
  },
);

Chevron.displayName = 'Chevron';
