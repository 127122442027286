import { UnreachableCaseException } from '@kontent-ai/errors';
import React from 'react';
import styled, { css } from 'styled-components';
import { ComponentLibraryGlobals } from '../../../globals/componentLibraryGlobals.ts';
import { colorIconDefaultInverse, colorIconDisabled } from '../../../tokens/decision/colors.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { AnimatedProgressIcon } from '../../Icons/AnimatedProgressIcon.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';
import { ButtonSize } from '../buttonSize.ts';
import { IBaseButtonComponentProps } from '../components/BaseButtonComponent.tsx';
import { StyledIconButton } from '../components/StyledIconButton.tsx';
import { getIconColor } from '../utils/stylingUtils.ts';
import { IconButtonState } from './IconButtonState.ts';

interface IStyledIconProps
  extends Pick<IBaseButtonComponentProps, 'buttonStyle' | 'disabled' | 'destructive'> {
  readonly $activated?: boolean;
}

interface IIconButtonIconProps extends IStyledIconProps {
  readonly buttonState?: IconButtonState;
  readonly className?: string;
  readonly iconName: IconName;
  readonly size: ButtonSize;
}

const getIconSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return IconSize.S;
    case 'medium':
      return IconSize.M;
    case 'large':
      return IconSize.L;
    default:
      throw UnreachableCaseException(size);
  }
};

const getDestructiveHoverColor = (disabled?: boolean) => css`
  color: ${disabled ? colorIconDisabled : colorIconDefaultInverse}
`;

const getIcon = (iconName: IconName, buttonState?: IconButtonState) => {
  if (buttonState === 'in-progress') {
    return AnimatedProgressIcon;
  }
  const resolvedIconName = Object.keys(Icons).find((i) => i === iconName);

  if (!resolvedIconName) {
    ComponentLibraryGlobals.logError(`Could not find ${iconName} among icons.`);
  }

  return iconName && resolvedIconName ? Icons[resolvedIconName] : Icons.Bug;
};

const IconButtonIcon: React.FC<IIconButtonIconProps> = ({
  buttonState,
  className,
  iconName,
  size,
}) => {
  const ResolvedIcon = getIcon(iconName, buttonState);
  return <ResolvedIcon size={getIconSize(size)} className={className} />;
};

IconButtonIcon.displayName = 'IconButtonIcon';

export const StyledIconButtonIcon = styled(IconButtonIcon)<
  Pick<IStyledIconProps, 'destructive' | 'disabled' | '$activated'>
>`
  color: ${({ buttonStyle, disabled, destructive, $activated }) =>
    getIconColor(buttonStyle, disabled, destructive, $activated)};

  ${StyledIconButton}:hover &&,
  ${StyledIconButton}:active && {
    ${({ destructive, disabled }) => (destructive ? getDestructiveHoverColor(disabled) : '')};
  }
`;
